/* .invalid-image {
  background: linear-gradient(
      0deg,
      rgba(7, 16, 100, 0.21) 0%,
      rgba(7, 16, 100, 0.21) 100%
    ),
    url("../../../assets/images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100vh;
}

.filter-blur {
  backdrop-filter: blur(7.5px);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.invalid-container {
  z-index: 2;
  top: 45%;
  left: 20%;
  text-align: center;
  position: absolute;
} */
.invalid-main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(7, 16, 100, 0.1) 0%,
      rgba(7, 16, 100, 0.1) 100%
    ),
    no-repeat url("../../../assets/images/invalid-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.invalid-container {
  text-align: center;
}

.invalid-container h2 {
  font-family: "Poppins Bold";
  font-size: 50px;
  color: var(--inchcape-white);
}

.invalid-container h3 {
  font-family: "Poppins";
  font-size: 30px;
  color: var(--inchcape-white);
}
