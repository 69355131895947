.block-reschedule-information .reservation-data {
  margin-top: 10px;
}

.block-reschedule-information .reservation-data h5 {
  text-align: center;
}

.block-reschedule-information .label-text {
  color: #424242;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.block-reschedule-information .label-header {
  color: #424242;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
}

.block-reschedule-information .terms-condition {
  margin-top: 20px;
}

.block-reschedule-information .terms-condition-text {
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  color: var(--inchcape-blue);
}

.block-reschedule-information .header-text-data {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  color: var(--inchcape-blue);
}

.block-reschedule-information .MuiButtonBase-root.MuiCheckbox-root {
  color: var(--inchcape-blue) !important;
}

.block-reschedule-information .error {
  font-family: "Poppins";
  font-size: 12px;
  margin-top: 4px;
  color: var(--inchcape-red);
  display: flex;
  flex-direction: column;
}
