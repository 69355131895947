.header {
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--inchcape-grey);
  padding: 0 15px 0 30px;
  margin-bottom: 30px;
}

.header .details,
.header .profile {
  display: flex;
  align-items: center;
}

.header .details {
  gap: 24px;
}

.header .profile {
  gap: 15px;
}

.header h2 {
  font-size: 24px;
  color: var(--inchcape-blue);
  margin: 0;
}

.header h2,
.header .profile .name {
  font-family: "Poppins Bold";
}

.header .profile p {
  font-family: "Poppins Light";
}

.header .profile {
  font-size: 14px;
  color: var(--inchcape-grey);
}

.header .profile svg {
  filter: brightness(0) saturate(100%) invert(24%) sepia(26%) saturate(383%)
    hue-rotate(162deg) brightness(95%) contrast(89%);
}

.header .profile div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.header svg {
  width: 32px;
  height: 32px;
}

.header .details svg {
  filter: brightness(0) saturate(100%) invert(61%) sepia(32%) saturate(2367%)
    hue-rotate(152deg) brightness(98%) contrast(85%);
}

.header .details.reports svg {
  transform: scaleX(-1);
}
