.create-demo-modal
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  max-width: none !important;
  width: min(90vw, 739px);
  display: flex;
  gap: 40px;
  padding: 25px 50px;
  border-radius: 0;
}

.create-demo-modal h3 {
  font-family: "Poppins Bold";
  font-size: 24px;
  color: var(--inchcape-blue);
  text-align: center;
  margin: 0;
}

.create-demo-modal h4 {
  font-family: "Poppins Bold";
  font-size: 16px;
  color: var(--inchcape-blue);
  margin-bottom: 15px;
}
