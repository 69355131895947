.popover {
  width: 500px;
  height: 200px;
}

.custom-event {
  height: 100%;
}

.rbc-event:has(.block-event) {
  background-color: #dadee3 !important;
  color: var(--inchcape-grey) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  min-height: 60px;
}

.rbc-event:has(.test-drive-event) {
  background-color: var(--inchcape-blue) !important;
  color: var(--inchcape-white) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.rbc-event:has(.show-event) {
  background-color: var(--inchcape-green) !important;
  border: 1px solid #a4b800;
}

.rbc-event:has(.no-show-event) {
  background-color: var(--inchcape-red) !important;
  border: 1px solid #cd041d;
}
