.test-drive-calendar-container {
  margin-top: 20px;
  width: 100%;
}

.test-drive-calendar-container .calendar-container {
  margin-top: 5px;
  background-color: var(--inchcape-white);
}

.event-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tooltip-btn {
  width: 100px;
  height: 40px;
  font-size: 14px;
}

.event-tooltip .tooltip-btn.edit-btn {
  box-shadow: 0 4px 4px #00000040;
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
  border: none;
  margin-bottom: 10px;
}

.event-tooltip .tooltip-btn.cancel-btn {
  box-shadow: 0 4px 4px #00000040;
  background-color: var(--inchcape-white);
  color: var(--inchcape-blue);
  border: solid 1px var(--inchcape-blue);
  margin-bottom: 10px;
}

.test-drive-calendar-container .custom-event {
  display: flex;
  flex-direction: column;
}

.test-drive-calendar-container .custom-event .custom-slot {
  order: 1;
}

.test-drive-calendar-container .custom-event .time {
  order: 2;
}

.test-drive-calendar-container .custom-event .title {
  order: 3;
}

.test-drive-calendar-container .custom-event .brand {
  order: 4;
}
