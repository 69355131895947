* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

p {
  margin: 0;
}

button:focus-visible {
  outline: none;
}

/* Font Styles */
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/fonts/Poppins-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Poppins Light";
  src: url("../src/assets/fonts/Poppins-Light.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../src/assets/fonts/Poppins-SemiBold.ttf");
  font-style: normal;
}

/* Default Colors */
:root {
  --inchcape-white: #ffffff;
  --inchcape-blue: #16bae7;
  --inchcape-blue-hover: #2dc6f0;
  --inchcape-dark-blue: #00233a;
  --inchcape-grey: #39464f;
  --inchcape-dark-grey: #262626;
  --inchcape-light-grey: #858a8d80;
  --inchcape-light-grey-noOp: #858a8d;
  --inchcape-purple: #9e61a4;
  --inchcape-indigo: #7887fb;
  --inchcape-yellow: #f2e500;
  --inchcape-red: #e40521;
  --inchcape-green: #b7cd00;
}
