.multiselect-input .subtext {
  font-size: 10px;
  font-family: "Poppins Light";
  color: var(--inchcape-light-grey-noOp);
}

.multiselect-input .MuiFormControl-root {
  width: 100%;
}

.multiselect-input .MuiSelect-multiple {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  color: var(--inchcape-grey) !important;
}

.multiselect-input .MuiTypography-root {
  font-family: "Poppins" !important;
  font-size: 14px !important;
}

.multiselect-input .Mui-selected {
  background-color: rgba(25, 118, 210, 0.08) !important;
}

.multiselect-input .error {
  font-size: 12px;
  font-family: "Poppins";
  color: var(--inchcape-red);
}

.multiselect-input.error
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:before {
  border-bottom: 1px solid var(--inchcape-red) !important;
}

.MuiTypography-root {
  font-family: "Poppins" !important;
}
