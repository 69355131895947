.select-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-header > span {
  font-family: "Poppins Bold";
  text-align: center;
  line-height: 20.02px !important;
  color: var(--inchcape-grey);
}
