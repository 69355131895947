.demos .demo-filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 26px 45px;
}

.demos .demo-filter-title {
  color: var(--inchcape-blue);
  font-family: "Poppins";
  font-size: max(1.1vw, 18px);
}

.demos .demo-filters .btns {
  row-gap: 8px;
}
