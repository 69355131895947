/* Data grid */
.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor {
  border-radius: 0 !important;
  border-width: 1px 0 0 0 !important;
  border-color: #0000001f !important;
  height: 50vh !important;
  min-height: 500px !important;
}

/* Data grid first header */
.MuiDataGrid-root
  .MuiDataGrid-columnHeader:first-child
  .MuiDataGrid-columnHeaderTitleContainer,
.block.MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell:first-child {
  padding-left: 15px;
}

/* Data grid row first cell */
.MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell:first-child {
  padding-left: 20px;
}

/* Data grid pagination */
/* Data grid outline when clicking sort */
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: 0 !important;
}

/* Checkbox and Toggle switch circle */
.MuiDataGrid-checkboxInput,
.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked,
.Mui-checked {
  color: var(--inchcape-blue) !important;
}

/* Checkbox label */
.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: "Poppins" !important;
  color: var(--inchcape-grey);
  font-size: 14px;
  padding-top: 3px;
}

/* Radio button color */
.MuiRadio-root.Mui-checked,
.MuiButtonBase-root.MuiRadio-root {
  color: var(--inchcape-blue) !important;
}

/* Toggle switch bar */
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--inchcape-blue) !important;
}

/* Data grid header title */
.MuiDataGrid-columnHeaderTitle {
  font-family: "Poppins Bold";
  color: var(--inchcape-grey);
}

/* Data grid row */
.MuiDataGrid-root .MuiDataGrid-cellContent {
  font-family: "Poppins Light";
  color: #00233ae6;
}

/* Data grid labels, Pagination, Menu Item */
.MuiTablePagination-selectLabel,
.MuiInputBase-root.MuiTablePagination-input,
.MuiTablePagination-displayedRows,
.MuiTablePagination-menuItem,
.MuiDataGrid-selectedRowCount,
.switch-cell > span {
  font-size: 14px !important;
  font-family: "Poppins Light" !important;
  color: var(--inchcape-grey) !important;
}

/* Data grid action icons container */
.MuiDataGrid-cell--withRenderer .action-icons {
  width: 100%;
  display: flex;
  gap: 24px;
}

/* Data grid action icons */
.MuiDataGrid-cell--withRenderer .action-icons svg,
.MuiDataGrid-cell--withRenderer .action-icons img {
  width: 20px;
  height: 20px;
  filter: brightness(0) saturate(100%) invert(65%) sepia(44%) saturate(3104%)
    hue-rotate(151deg) brightness(98%) contrast(85%);
  cursor: pointer;
}

/* Data grid action icons on disable */
.MuiDataGrid-cell--withRenderer .action-icons span.disabled svg,
.MuiDataGrid-cell--withRenderer .action-icons span.disabled img {
  opacity: 0.45;
  cursor: default;
}

/* Data grid no results found */
.MuiDataGrid-overlay {
  font-family: "Poppins";
  font-size: 14px;
  color: var(--inchcape-grey);
}

/* Data grid row on select and hover */
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected,
.MuiDataGrid-root .MuiDataGrid-row:hover,
.MuiDataGrid-row:hover,
.MuiDataGrid-row.Mui-hovered {
  background-color: transparent !important;
}

.MuiDataGrid-root .MuiDataGrid-row,
.MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  min-height: 56px !important;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--inchcape-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 35, 58, 0.1);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 35, 58, 0.1);
}

/* Bootstrap row */
.row {
  margin: 0;
}

/* Delete and Reassign Modal */
.delete-reassign-modal .MuiPaper-root {
  padding: 50px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: none;
  border-radius: 0;
}

/* Delete and Reassign Modal Title */
.delete-reassign-modal h2 {
  font-family: "Poppins Bold";
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--inchcape-blue);
}

/* Delete and Reassign Modal Text Content */
.delete-reassign-modal .content {
  font-family: "Poppins Light";
  font-size: 16px;
  margin-bottom: 60px;
  color: var(--inchcape-grey);
}

/* Delete and Reassign Modal Title & Text Content */
.delete-reassign-modal h2,
.delete-reassign-modal .content {
  text-align: center;
}

/* Delete and Reassign Buttons */
.delete-reassign-modal .button {
  padding: 6px 23px;
  width: 125px;
}

/* Timepicker container div */
.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 0 !important;
  padding-right: 5px !important;
}

/* Timepicker hover */
.MuiInputBase-root.MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--inchcape-light-grey) !important;
}

/* Timepicker border */
.MuiOutlinedInput-notchedOutline {
  border-width: 0 0 1px 0 !important;
}

/* Timepicker label */
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root   */
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  color: var(--inchcape-grey);
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 25px !important;
}

/* Timepicker and datepicker font */
.MuiInputBase-input.MuiOutlinedInput-input,
.MuiButtonBase-root.MuiMenuItem-root.MuiMultiSectionDigitalClockSection-item,
.MuiButtonBase-root.MuiPickersDay-root,
.MuiPickersCalendarHeader-labelContainer,
.MuiPickersYear-yearButton,
.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  font-family: "Poppins Light" !important;
  color: var(--inchcape-grey);
}

.MuiButtonBase-root.MuiPickersDay-root:hover,
.MuiPickersYear-yearButton:hover,
.MuiButtonBase-root.MuiMenuItem-root.MuiMultiSectionDigitalClockSection-item:hover,
.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
  background-color: var(--inchcape-blue-hover) !important;
  color: var(--inchcape-white) !important;
}

/* Datepicker date & year selection */
.MuiButtonBase-root.MuiPickersDay-root,
.MuiPickersCalendarHeader-labelContainer,
.MuiPickersYear-yearButton {
  font-size: 14px !important;
}

/* Timepicker focus label */
.MuiInputLabel-shrink {
  font-family: "Poppins" !important;
  color: var(--inchcape-blue) !important;
  line-height: 2.4rem !important;
  /* left: -13px !important; */
}

/* Timepicker and datepicker selected time */
.MuiPickersDay-root.Mui-selected,
.MuiMultiSectionDigitalClockSection-item.Mui-selected,
.MuiPickersYear-yearButton.Mui-selected {
  background-color: var(--inchcape-blue) !important;
  color: var(--inchcape-white) !important;
}

/* Date & Timepicker icon */
.calendar-input .MuiButtonBase-root,
.time-picker-input .MuiButtonBase-root {
  margin-top: 14px !important;
}

/* Timepicker value text */
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
  padding: 16px 14px 8px 2px !important;
}

/* Block module datepicker */
.block-mod
  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
  font-family: "Poppins" !important;
  font-size: 14px;
  line-height: 32px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--inchcape-light-grey) !important;
}

/* Dropdown focus background-color */
.MuiSelect-select.MuiSelect-standard.MuiInputBase-input:focus {
  background-color: var(--inchcape-white) !important;
}

.block-mod
  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd,
.reports
  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEndt {
  padding: 16px 14px 8px 2px !important;
}

.calendar-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
.time-picker-input
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  margin-left: -13px !important;
}

/* Block module header */
.block-mod .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
  height: 105px !important;
  max-height: none !important;
  line-height: 1.2 !important;
}

/* Block module first header cell */
.block-mod
  /* .MuiDataGrid-root */
  .MuiDataGrid-columnHeader:first-child
  .MuiDataGrid-columnHeaderTitleContainer {
  padding-left: 0;
  white-space: wrap;
}

/* Block module first cell */
.block-mod .MuiDataGrid-root .MuiDataGrid-cellCheckbox {
  padding-left: 29px !important;
}

.block-mod .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  overflow: initial;
}

.MuiDataGrid-footerContainer {
  height: 67px !important;
  border-top: 1px solid #0000001f !important;
}

/* Tooltip */
.MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementTop {
  background-color: var(--inchcape-purple) !important;
  font-family: "Poppins" !important;
  font-size: 12px !important;
  color: var(--inchcape-white) !important;
}

.MuiTooltip-arrow {
  color: var(--inchcape-purple) !important;
}

/* Text Area Default Styling */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline {
  padding: 16px 14px 8px 2px !important;
}

.text-area .MuiFormLabel-root.MuiInputLabel-root {
  left: -13px !important;
}

/* Scheduler Custom Layout */
/* .calendar-container .rbc-calendar {
  height: 58vh;
} */

/* .scheduler .rbc-calendar {
  height: 63vh;
} */

.MuiPickersCalendarHeader-label {
  text-transform: capitalize;
}

.rbc-today {
  color: var(--inchcape-blue);
}

.rbc-custom-header {
  padding: 10px 15px;
  margin-bottom: 5px;
  border-radius: var(--none, 0px);
  border: 1px solid #dadee3;
}

.rbc-header.rbc-now a span {
  color: var(--inchcape-blue) !important;
}

.rbc-custom-header button {
  border: 1px solid #ddd;
  font-family: "Poppins";
  font-size: 14px;
  color: var(--inchcape-dark-grey);
  background-color: #f5f5f5;
  padding: 5px 9px;
}

.rbc-custom-header button:hover {
  background-color: #ddd;
}

.rbc-custom-header button.selected {
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
  border: 1px solid var(--inchcape-blue);
}

.rbc-custom-header button:first-child {
  border-radius: 4px 0 0 4px;
}

.rbc-custom-header button:nth-child(3) {
  border-radius: 0 4px 4px 0px;
  margin-right: 17px;
}

.rbc-custom-header .rbc-action-btn span {
  display: block;
  font-family: "Poppins";
  font-size: 16px;
}

.rbc-custom-header .rbc-action-btn .calendar-icon {
  width: 24px;
  height: 24px;
  margin-right: 3px;
}

.rbc-custom-header .arrow-icon {
  width: 20px;
  height: 20px;
}

.rbc-header {
  text-align: center;
  padding: 16px 12px;
  border-bottom: 1px solid #ddd;
}

.rbc-month-row {
  min-height: 92px;
}

.rbc-date-cell {
  text-align: right;
  padding: 5px 10px 0 0;
}

.rbc-event[title~="null"] {
  background-color: #dadee3 !important;
}

.rbc-event[title~="New"] {
  background-color: var(--inchcape-white) !important;
}

.rbc-event:has(#new) {
  background-color: var(--inchcape-white) !important;
  border: 1px solid #265985 !important;
}

.rbc-event {
  background-color: var(--inchcape-blue) !important;
  border-radius: 0;
}

.rbc-date-cell.rbc-now > a {
  background-color: var(--inchcape-dark-blue) !important;
}

.rbc-event-content {
  font-family: "Poppins";
  font-size: 12px;
}

.rbc-day-slot .rbc-time-slot {
  border: none;
}

.rbc-time-content > .rbc-time-gutter {
  min-width: 162px !important;
  text-align: center !important;
}

.rbc-header-gutter {
  min-width: 162px !important;
}

.rbc-time-column {
  min-height: 46px !important;
}

.rbc-label {
  padding: 16px 12px;
}

.rbc-timeslot-group {
  min-height: 57px !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-day-slot .rbc-event {
  display: flex;
  flex-direction: column !important;
  flex-flow: nowrap;
}

.rbc-event-label {
  font-family: "Poppins";
  font-size: 12px;
}

.rbc-label {
  font-family: "Poppins Bold";
  font-size: 12px;
  color: var(--inchcape-grey);
  text-align: center;
  text-transform: uppercase;
}

.rbc-time-content {
  border-top: 1px solid #ddd;
}

.rbc-day-slot .rbc-timeslot-group .disabled {
  background: #39464f0d;
  user-select: none;
}

.rbc-row:has(.rbc-allday-cell) {
  display: none;
}

.rbc-today .rbc-timeslot-group {
  background: white;
}
