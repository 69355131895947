.create-demo-modal .vehicle-status-box {
  display: flex;
  flex-direction: column;
}

.create-demo-modal .vehicle-status-box h4 {
  margin-bottom: 30px;
}

.create-demo-modal .vehicle-status-box > span {
  display: inline-block;
  font-family: "Poppins";
  font-size: 14px;
  color: var(--inchcape-dark-grey);
  margin-bottom: 15px;
}
