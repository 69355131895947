.create-block-showroom .block-info h3 {
  font-size: 18px;
}

.create-block-showroom .block-info > span {
  font-size: 16px;
}

.create-block-showroom .block-info .dates,
.create-block-showroom .block-info .hours {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 45px;
  align-items: center;
}

.create-block-showroom .block-info span.error {
  display: inline-block;
  font-family: "Poppins";
  font-size: 12px;
  color: var(--inchcape-red);
  margin-top: 4px;
}

.create-block-showroom .block-info div.error .MuiOutlinedInput-notchedOutline {
  border-color: var(--inchcape-red) !important;
}

.create-block-showroom .MuiButtonBase-root.MuiCheckbox-root {
  color: var(--inchcape-blue) !important;
}
