.dropdown-input
  .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input,
.dropdown-input .MuiFormControl-root {
  min-width: 125px !important;
  width: 100% !important;
  font-size: 14px !important;
}

.MuiSelect-nativeInput {
  height: 45px;
}

.MuiInputBase-root-MuiInput-root-MuiSelect-root,
.MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before,
.MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.Mui-focused fieldset {
  font-family: "Poppins" !important;
  border-color: var(--inchcape-light-grey) !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before,
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:after,
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:before {
  border-bottom: 1px solid var(--inchcape-light-grey) !important;
}

.MuiFormLabel-root.MuiInputLabel-root,
.dropdown-input .MuiSelect-select.MuiSelect-standard.MuiInputBase-input {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--inchcape-grey) !important;
  padding: 1px 0 5px !important;
}

.MuiInputLabel-animated.MuiInputLabel-shrink {
  font-family: "Poppins" !important;
  color: var(--inchcape-blue) !important;
}

.MuiMenuItem-root.MuiMenuItem-gutters {
  font-family: "Poppins" !important;
  color: var(--inchcape-grey) !important;
  font-size: 14px !important;
}

.dropdown-input.error
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:before {
  border-bottom: 1px solid var(--inchcape-red) !important;
}

.dropdown-input .error {
  font-family: "Poppins";
  font-size: 12px;
  margin-top: 4px;
  color: var(--inchcape-red);
}

.dropdown-input.error {
  display: flex;
  flex-direction: column;
}

/* .Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters {
  background-color: var(--inchcape-blue) !important;
  color: var(--inchcape-white) !important;
} */
