.create-block-demo .vehicle-info h3,
.create-block-demo .vehicle-info > span {
  display: block;
  margin-bottom: 25px;
}

.create-block-demo .vehicle-dropdown p {
  font-family: "Poppins";
  font-size: 12px;
  color: var(--inchcape-blue);
}

.create-block-demo .vehicle-dropdown span:not(.error) {
  padding: 5px;
}
