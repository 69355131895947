.blocked-filters-container {
  padding: 20px;
  width: 100%;
  background-color: var(--inchcape-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.block-filters-container {
  padding: 40px 27.5px;
  width: 100%;
  background-color: var(--inchcape-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
