.test-drive-reschedule-information .reservation-data {
	margin-bottom: 20px;
}

.test-drive-reschedule-information .reservation-data h5 {
	text-align: center;
	margin-bottom: 30px;
	font-size: 18px;
}

.test-drive-reschedule-information .client-data {
	margin-bottom: 10px;
}

.test-drive-reschedule-information .client-data h5 {
	text-align: center;
	margin-bottom: 30px;
	font-size: 18px;
}

.test-drive-reschedule-information .label-text {
	color: #424242;
	font-family: "Poppins";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
}

.test-drive-reschedule-information .label-header {
	color: #424242;
	font-family: "Poppins";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 19px;
}

.test-drive-reschedule-information .terms-condition {
	margin-top: 20px;
	font-family: "Poppins";
	font-size: 14px;
	margin-bottom: 50px;
}

.test-drive-reschedule-information .terms-condition-text {
	font-style: normal;
	color: var(--inchcape-blue);
	cursor: pointer;
	text-decoration: none;
	display: inline;
}
.test-drive-reschedule-information div.error .MuiOutlinedInput-notchedOutline {
	border-color: var(--inchcape-red) !important;
}

.test-drive-reschedule-information .MuiFormControl-root.MuiTextField-root {
	width: 100%;
}
