.create-block-demo
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  padding: 50px 30px !important;
  max-width: none;
  width: 814px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.create-block-demo span {
  font-family: "Poppins";
  font-size: 14px;
  color: var(--inchcape-grey);
}

.create-block-demo h2,
.create-block-demo h3 {
  font-family: "Poppins Bold";
  color: var(--inchcape-blue);
}

.create-block-demo h2 {
  text-align: center;
  font-size: 24px;
}

.create-block-demo h3 {
  font-size: 16px;
}

.create-block-demo .btn-group {
  display: flex;
  align-self: center;
  gap: 23px;
}
