.create-block-showroom .showroom-info .showrooms {
	display: grid;
	grid-template-columns: repeat(3, 196px);
	gap: 30px;
}

.create-block-showroom .showroom-info .showrooms p:first-of-type {
	color: var(--inchcape-blue);
	font-size: 12px;
	font-family: "Poppins Light";
}

.create-block-showroom .showroom-info .showrooms span {
	color: var(--inchcape-grey);
	padding: 5px 0 5px 8px;
	font-family: "Poppins Light";
	font-size: 14px;
	display: inline-block;
}
