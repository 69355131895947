.block-calendar-modal .MuiPaper-root.MuiDialog-paper {
  padding: 50px 34px;
  border-radius: 0;
  min-width: 520px;
  max-width: 770px;
  min-height: 329px;
  max-height: 935px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.block-calendar-modal h2 {
  font-size: 24px;
}

.block-calendar-modal h5,
.block-calendar-modal h3,
.block-calendar-modal h2 {
  font-family: "Poppins Bold";
  color: var(--inchcape-blue);
}

.block-calendar-modal .btns {
  display: flex;
  gap: 35px;
}

.block-calendar-modal .header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block-calendar-modal .date-text {
  color: var(--inchcape-blue);
  font-size: 16px;
  font-style: normal;
}

.block-calendar-modal span {
  font-size: 16px;
  font-family: "Poppins";
  color: var(--inchcape-grey);
}
