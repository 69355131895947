.block.popover {
  border-radius: 2px;
  box-shadow: 0 4px 4px 0 #00000040;
  display: flex;
  flex-direction: column;
  gap: 29px;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 277px;
  border: none;
}

.block.popover > div {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.block.popover h3 {
  font-family: "Poppins Bold";
  font-size: 16px;
}

.block.popover label,
.block.popover h3 {
  color: var(--inchcape-blue);
}

.block.popover span {
  font-size: 13px;
  font-family: "Poppins";
  color: var(--inchcape-grey);
}
