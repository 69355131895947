.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft.notification {
  top: 114px !important;
  left: 300px !important;
  right: auto;
  color: var(--inchcape-white);
  width: calc(100vw - 300px);
  max-height: 55px;
}

.notification.success {
  background-color: var(--inchcape-blue);
}

.notification.error {
  background-color: var(--inchcape-red);
}

.notification {
  padding: 6px 16px;
}

.notification span {
  font-family: "Poppins";
  font-size: 16px;
}

.notification svg {
  width: 22px;
  height: 22px;
}

.notification svg {
  margin-right: 12px !important;
}

.notification .close-btn {
  cursor: pointer;
}
