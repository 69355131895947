.reassign-modal .MuiPaper-root.MuiDialog-paper {
  padding: 50px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: none;
  border-radius: 0;
}

.reassign-modal h2 {
  font-family: "Poppins Bold";
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--inchcape-blue);
}
