.block-demo .calendar-filter {
  padding: 10px 29px 10px 25px;
  border: 1px solid #dadee3;
  margin-bottom: 5px;
}

.block-demo .calendar-filter .close-icon-box {
  border: 1px solid var(--inchcape-blue);
  color: var(--inchcape-blue);
  padding: 6px;
  cursor: pointer;
}

.block-demo .calendar-filter .close-icon {
  width: 24px;
  height: 24px;
}

.block-demo .calendar-filter .legend-group {
  gap: 49px;
}

.block-demo .calendar-filter .legend-group span {
  font-family: "Poppins";
  font-size: 14px;
}

.block-demo .calendar-filter .legend-group > div > div {
  width: 20px;
  height: 20px;
}

.block-demo .calendar-filter .legend-group .legend-1 {
  border: 1px solid #545d69;
  background-color: var(--inchcape-white);
}

.block-demo .calendar-filter .legend-group .legend-2 {
  border: 1px solid #dadee3;
  background-color: #dadee3;
}

.block-demo .calendar-filter .legend-group .legend-3 {
  border: 1px solid var(--inchcape-blue);
  background-color: var(--inchcape-blue);
}
