.test-drive-calendar-modal
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  padding: 50px 34px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 675px;
  align-items: center;
}

.test-drive-calendar-modal
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm:has(
    .cancel-container
  ) {
  max-width: 507px;
}

.test-drive-calendar-modal h2 {
  font-size: 24px;
  text-align: center;
}

.test-drive-calendar-modal .cancel-container h2 {
  margin-bottom: 53px;
}

.test-drive-calendar-modal h5,
.test-drive-calendar-modal h3,
.test-drive-calendar-modal h2 {
  font-family: "Poppins Bold";
  color: var(--inchcape-blue);
}

.test-drive-calendar-modal .btns {
  display: flex;
  gap: 35px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.test-drive-calendar-modal .header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test-drive-calendar-modal .date-text {
  color: var(--inchcape-blue);
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  margin-bottom: 12px;
}
