.test-drive-information-container {
  display: flex;
  padding: var(--none, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  text-align: center;
  margin-bottom: 53px;
}

.test-drive-information-container span {
  font-family: "Poppins";
  font-size: 16px;
}

.test-drive-information-container .date-text {
  color: var(--inchcape-grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.352px;
}
