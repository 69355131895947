.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl {
	color: var(--inchcape-grey) !important;
	font-family: "Poppins" !important;
	font-size: 14px !important;
}

.text-input .MuiFormControl-root.MuiTextField-root {
	width: 100%;
}

.text-input .MuiInputBase-root.MuiInput-root:before {
	border-bottom: 1px solid var(--inchcape-light-grey) !important;
}

.text-input .error {
	color: var(--inchcape-red);
	font-size: 12px;
	font-family: "Poppins";
	margin-top: 4px;
}

.text-input.error
	.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:before {
	border-bottom: 1px solid var(--inchcape-red) !important;
}
