.block-calendar-container {
  margin-top: 20px;
  width: 100%;
}

.block-calendar-container .calendar-container {
  margin-top: 5px;
  background-color: var(--inchcape-white);
}

.event-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tooltip-btn {
  width: 100px;
  height: 40px;
  font-size: 14px;
  margin-top: 10px;
}

.event-tooltip .tooltip-btn.edit-btn {
  box-shadow: 0 4px 4px #00000040;
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
  border: none;
  margin-bottom: 10px;
}

.event-tooltip .tooltip-btn.create-btn {
  box-shadow: 0 4px 4px #00000040;
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
  border: solid 1px var(--inchcape-blue);
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.event-details {
  height: 100%;
}
