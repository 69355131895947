.create-demo-modal .demo-image-upload {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.create-demo-modal .upload-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  text-align: center;
  border: 1px dashed var(--inchcape-blue);
  border-radius: 4px;
}

.create-demo-modal .upload-box.error {
  border: 1px dashed var(--inchcape-red) !important;
  background-color: rgba(228, 5, 33, 0.04);
}

.create-demo-modal .upload-box > div:first-child {
  padding: 8px;
}

.create-demo-modal .upload-box > div > svg {
  width: 24px;
  height: 24px;
  color: var(--inchcape-blue);
}

.create-demo-modal .upload-box > div > svg.error {
  color: var(--inchcape-red);
}

.create-demo-modal .upload-box .drag-text {
  font-family: "Poppins";
  font-size: 16px;
  color: var(--inchcape-grey) !important;
  text-decoration: none !important;
}

.create-demo-modal .upload-box > div:nth-child(2) > span {
  font-family: "Poppins";
  font-size: 16px;
  color: var(--inchcape-grey);
}

.create-demo-modal .upload-box > div:nth-child(2) > span:first-child {
  color: var(--inchcape-blue);
  text-decoration: underline;
  cursor: pointer;
}

.create-demo-modal .upload-box p {
  font-family: "Poppins";
  font-size: 14px;
  color: #858a8d;
  margin: 0;
}

.create-demo-modal .image-uploaded-box {
  display: flex;
  align-items: center;
  margin-top: 12px;
  gap: 8px;
}

.create-demo-modal .image-uploaded-box > p {
  font-family: "Poppins";
  font-size: 15px;
  color: var(--inchcape-blue);
  text-decoration: underline;
  margin: 0;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.create-demo-modal .image-uploaded-box .check-icon {
  color: var(--inchcape-green) !important;
}

.create-demo-modal .image-uploaded-box .trash-icon {
  color: var(--inchcape-grey) !important;
  cursor: pointer;
}

.create-demo-modal .upload-box .error-msg {
  font-family: "Poppins";
  font-size: 14px;
  color: var(--inchcape-red);
}
