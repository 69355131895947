.test-drive-schedule-information .reservation-data {
	margin-top: 10px;
}

.test-drive-schedule-information .reservation-data h5 {
	text-align: center;
	margin-bottom: 30px;
	margin-top: 20px;
	font-size: 18px;
}

.test-drive-schedule-information .label-text {
	color: #424242;
	font-family: "Poppins";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
}

.test-drive-schedule-information .label-header {
	color: #424242;
	font-family: "Poppins";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 19px;
}

.test-drive-schedule-information .terms-condition {
	margin-top: 20px;
	font-family: "Poppins";
	font-size: 14px;
	margin-bottom: 50px;
}

.test-drive-schedule-information .terms-condition-text {
	font-style: normal;
	color: var(--inchcape-blue);
	cursor: pointer;
	text-decoration: none;
	display: inline;
}

.test-drive-schedule-information div.error .MuiOutlinedInput-notchedOutline {
	border-color: var(--inchcape-red) !important;
}

.test-drive-schedule-information .reservation-data:first-of-type > div {
	width: 85%;
	margin-left: auto;
	margin-right: auto;
}
