.landing-main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding: 61px 121px;
  background: linear-gradient(
      0deg,
      rgba(7, 16, 100, 0.1) 0%,
      rgba(7, 16, 100, 0.1) 100%
    ),
    no-repeat url("../../../assets/images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-container {
  display: flex;
  flex-direction: column;
  gap: 121px;
}

.landing-container div {
  display: flex;
  flex-direction: column;
  gap: 90px;
}

.landing-container img {
  width: 210px;
}

.landing-container h1 {
  font-family: "Poppins Bold";
  font-size: 96px;
  color: var(--inchcape-white);
}

.landing-container button {
  width: 218px;
  font-size: 32px;
  line-height: 38px;
  padding: 7px 32px;
}
