.business-hrs .day-rows {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.business-hrs > span {
  color: #5c5c5c;
}

.business-hrs .MuiFormControlLabel-label {
  font-family: "Poppins";
  color: #262626 !important;
  font-size: 14px;
}

.business-hrs .MuiFormControlLabel-root {
  height: 24px !important;
  margin-top: 10px;
  width: 375px;
}

.business-hrs .MuiTypography-root {
  font-size: 14px !important;
}
