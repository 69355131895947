.main-cont {
  display: flex;
  height: 100vh;
}

.right-cont {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 0 16px 30px 30px;
  background-color: #f4f6f9;
}

.right-cont .cont {
  width: 100%;
  background-color: var(--inchcape-white);
}
