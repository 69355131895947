.block-information-container {
    display: flex;
    padding: var(--none, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
    text-align: center;
  }
  
  .block-information-container .date-text {
    color: var(--inchcape-grey);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.352px;
  }
  