.create-block-demo .info h3 {
  margin-bottom: 40px;
}

.create-block-demo .info-date > span,
.create-block-demo .info-time > span {
  display: block;
  margin-bottom: 25px;
}

.create-block-demo .info-date .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.create-block-demo .info-date {
  margin-bottom: 40px;
}

.create-block-demo .info-date .error,
.create-block-demo .info-time .error {
  font-family: "Poppins";
  font-size: 12px;
  color: var(--inchcape-red);
}

.create-block-demo
  .info-time
  .error
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline,
.create-block-demo
  .info-date
  .error
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--inchcape-red) !important;
}

.create-block-demo .MuiButtonBase-root.MuiCheckbox-root {
  color: var(--inchcape-blue) !important;
}
