.cont.reports {
  display: flex;
  flex-direction: column;
  gap: 54px;
  background-color: transparent;
}

.reports .filters {
  background-color: var(--inchcape-white);
  padding: 40px 20px 40px 20px;
  box-shadow: 0 4px 4px #00000040;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.reports .grid {
  background-color: var(--inchcape-white);
}

.reports .grid button {
  margin: 13px 16px;
}

.reports
  .MuiDataGrid-root
  .MuiDataGrid-columnHeader:first-child
  .MuiDataGrid-columnHeaderTitleContainer {
  padding-left: 7px !important;
}

.reports .MuiDataGrid-root .MuiDataGrid-cell--textLeft:first-child {
  padding-left: 18px !important;
}
