.sidebar {
  box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  background-color: var(--inchcape-white);
  border-right: 1px solid #00000026;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 15px;
  min-width: 300px;
  width: 16vw;
  gap: 33px;
  max-width: 420px;
  min-height: 600px;
}

.sidebar .inch-logo {
  width: clamp(180px, 10vw, 220px);
  min-height: 50px;
}

.sidebar h3 {
  width: 100%;
  text-align: center;
  font-family: "Poppins Bold";
  letter-spacing: 0.17px;
  font-size: max(135%, 20px);
  user-select: none;
}

.sidebar .nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  max-height: 600px;
}

.sidebar .nav ul {
  margin: 0;
  padding: 0;
  border-top: 1px solid #00000026;
}

.sidebar ul .nav-link {
  border-bottom: 1px solid #00000026;
}

.sidebar .nav > .nav-link:last-child {
  border-bottom: 1px solid transparent;
}

.sidebar .nav-link,
.sidebar .nav-link.active,
.sidebar .nav-link:focus,
.sidebar .nav-link:hover,
.sidebar h3 {
  color: var(--inchcape-grey);
}

.sidebar .nav-link {
  margin: 8px;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 8px 16px;
  font-family: "Poppins";
  font-size: 16px;
  height: 48px;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  background-color: #16bae733;
  border-bottom: 1px solid transparent;
}

.sidebar .nav-link.active {
  font-family: "Poppins Bold";
}

.sidebar .nav-link svg {
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate(100%) invert(24%) sepia(35%) saturate(269%)
    hue-rotate(161deg) brightness(94%) contrast(92%);
}
