.block-showroom .filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 26px 45px 26px;
}

.block-showroom .filters > span {
  font-size: max(1.1vw, 18px);
  font-family: "Poppins";
  color: var(--inchcape-blue);
}

.block-showroom .filters .btns {
  gap: 8px;
}
