.day-row {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  column-gap: 130px;
}

.day-row .hours {
  display: flex;
  gap: 80px;
}

.day-row .day-active {
  display: grid;
  grid-template-columns: 60px 40px;
  column-gap: 15px;
  align-items: center;
  align-content: end;
}

.day-row .day-active > span {
  font-family: "Poppins";
  font-size: 14px;
  color: #262626;
}

.day-row span.error {
  display: inline-block;
  font-family: "Poppins";
  font-size: 12px;
  color: var(--inchcape-red);
  margin-top: 4px;
}

.day-row div.error .MuiOutlinedInput-notchedOutline {
  border-color: var(--inchcape-red) !important;
}
