.create-block-showroom
	.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
	padding: 50px 34px;
	border-radius: 0;
	width: 769px;
	max-width: none;
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;
}

.create-block-showroom h2 {
	font-size: 24px;
}

.create-block-showroom h3,
.create-block-showroom h2 {
	font-family: "Poppins Bold";
	color: var(--inchcape-blue);
}

.create-block-showroom .block-reason,
.create-block-showroom .block-info,
.create-block-showroom .showroom-info {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.create-block-showroom .block-reason > span,
.create-block-showroom .block-info > span,
.create-block-showroom .showroom-info > span {
	color: var(--inchcape-grey);
	font-family: "Poppins";
}

.create-block-showroom .block-reason h3,
.create-block-showroom .showroom-info h3 {
	font-size: 16px;
}

.create-block-showroom .block-reason > span,
.create-block-showroom .showroom-info > span {
	font-size: 14px;
}

.create-block-showroom .btns {
	display: flex;
	gap: 35px;
}

/* Dropdowns */
.create-block-showroom.MuiModal-root .dropdown-input {
	width: 216px;
}
