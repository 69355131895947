.create-showroom
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  padding: 20px 50px !important;
  max-width: none;
  width: 789px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  scroll-behavior: smooth;
}

.create-showroom h2,
.create-showroom h3 {
  font-family: "Poppins Bold";
  color: var(--inchcape-blue);
}

.create-showroom h2 {
  font-size: 24px;
}

.create-showroom h3 {
  font-size: 16px;
}

.create-showroom .infos,
.create-showroom .business-hrs,
.create-showroom .status {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.create-showroom .business-hrs > span,
.create-showroom .status > span {
  font-size: 14px;
  font-family: "Poppins";
}

.create-showroom .btn-group {
  display: flex;
  gap: 23px;
}

.create-showroom
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm
  > .error {
  font-size: 14px;
  font-family: "Poppins";
  color: var(--inchcape-red);
}
