.button {
  width: auto;
  padding: 6px 42px;
  font-family: "Poppins Bold";
  border: 1px solid var(--inchcape-blue);
}

.button div svg,
.button div img {
  width: 18px;
  height: 18px;
}

.button.primary,
.button.secondary {
  width: max(9vw, 155px);
}

.button.primary {
  box-shadow: 0 4px 4px #00000040;
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
}

.button.secondary {
  box-shadow: 0 5px 4px #0000001f;
  color: var(--inchcape-blue);
  background-color: var(--inchcape-white);
}

.button.create,
.button.export {
  color: var(--inchcape-blue);
  background-color: var(--inchcape-white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  text-transform: uppercase;
  font-family: "Poppins Bold";
  font-size: 14px;
  padding: 4px 24px 4px 5px;
  border-right: 1px solid #0000001f;
}

.button.multiple {
  color: var(--inchcape-blue);
  background-color: var(--inchcape-white);
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  text-transform: uppercase;
  font-family: "Poppins Bold";
  font-size: 14px;
  padding: 4px 5px;
}

.button.show:disabled,
.button.button.no-show:disabled,
.button.edit:disabled,
.button.trash:disabled,
.button.multiple:disabled,
.button.create:disabled,
.button.primary:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.button.button.primary:hover {
  background-color: var(--inchcape-blue-hover);
  transition: all 0.3s ease;
}

.button.secondary:hover {
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
  transition: all 0.3s ease;
}

.button.calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: var(--inchcape-blue);
  color: #fff;
  width: 130px;
  padding: 0;
  height: 40px;
}

.button.edit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 130px;
  padding: 0;
  height: 40px;
  box-shadow: 0 4px 4px #00000040;
  background-color: var(--inchcape-blue);
  color: var(--inchcape-white);
  border: none;
  margin-bottom: 0px;
}

.button.trash {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 130px;
  padding: 0;
  height: 40px;
  box-shadow: 0 4px 4px #00000040;
  background-color: var(--inchcape-white);
  color: var(--inchcape-blue);
  margin-bottom: 0px;
  border: solid 1px var(--inchcape-blue);
}

.button.show {
  width: 96px;
  height: 25px;
  background: var(--inchcape-green);
  color: white;
  border-radius: 0;
  font-size: 13px;
  padding: 0;
  border: none;
  box-shadow: 0 4px 4px #00000040;
}

.button.no-show {
  width: 96px;
  height: 25px;
  background: var(--inchcape-red);
  color: white;
  border-radius: 0;
  font-size: 13px;
  padding: 0;
  border: none;
  box-shadow: 0 4px 4px #00000040;
}