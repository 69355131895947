.tdoverlay.popover {
	border-radius: 2px;
	box-shadow: 0 4px 4px 0 #00000040;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	width: 160px;
	border: none;
	height: 140px;
}

.tdoverlay.popover > div {
	display: flex;
	flex-direction: column;
	gap: 7px;
}

.tdoverlay.popover h3 {
	font-family: "Poppins Bold";
	font-size: 16px;
}

.tdoverlay.popover label,
.tdoverlay.popover h3 {
	color: var(--inchcape-blue);
}

.tdoverlay.popover span {
	font-size: 13px;
	font-family: "Poppins";
	color: var(--inchcape-grey);
}
.tdoverlay.popover button {
	width: 130px;
	justify-content: flex-start;
	padding: 0 15px;
	gap: 12px;
}

.sns-overlay.popover {
	width: 300px;
	min-height: 200px;
	padding: 20px;
	border-radius: 0;
	height: auto;
}

.sns-overlay .upper-info {
	font-size: 12px;
	color: var(--inchcape-blue);
	font-family: "Poppins";
}

.sns-overlay .lower-info {
	font-size: 10px;
	color: var(--inchcape-grey);
	font-family: "Poppins Light";
	margin-top: 10px;
}
