.disclaimer-modal .MuiPaper-root.MuiDialog-paper {
	max-width: none !important;
	width: min(90vw, 1298px);
	border-radius: 0 !important;
	padding: 70px;
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.disclaimer-modal h2 {
	font-family: "Poppins Bold";
	font-size: 26px;
	color: var(--inchcape-blue);
}

.disclaimer-modal span {
	color: black;
	font-family: "Poppins";
	font-size: 12px;
}

.disclaimer-modal button {
	align-self: flex-end;
}
