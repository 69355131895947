.block-demo .top {
  padding: 11px 20px;
}

.block-demo .top-calendar {
  background-color: #f4f6f9;
  padding: 40px 0 24px 0;
}

.block-demo .views {
  display: flex;
  align-items: center;
  gap: 10px;
}

.block-demo .views > span {
  font-family: "Poppins";
  font-size: 16px;
  color: var(--inchcape-grey);
}

.block-demo .icons {
  border-radius: 2px;
  height: 32px;
  width: 132px;
  display: flex;
}

.block-demo .icons > span {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--inchcape-blue);
  background: white;
}

.block-demo .icons svg {
  height: 24px;
  width: 24px;
  filter: brightness(0) saturate(100%) invert(57%) sepia(88%) saturate(723%)
    hue-rotate(154deg) brightness(93%) contrast(95%);
}

.block-demo .views span.selected {
  background-color: var(--inchcape-blue);
}

.block-demo .views span.selected svg {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(52deg) brightness(107%) contrast(102%);
}

.block-demo .btns {
  display: flex;
}

.block-demo .calendar-btn.disabled {
  opacity: 0.5;
  border: 1px solid #16bae780;
  cursor: default;
}

.block-demo .calendar-btn {
  opacity: 1;
}
